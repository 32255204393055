

   .switch {
    position: relative;
    display: inline-block;
    width: 40px; /* Adjusted width */
    height: 20px; /* Adjusted height */
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    width: 100%; /* Use width 100% to cover the entire switch */
    height: 100%; /* Use height 100% to cover the entire switch */
    background-color: #594FF5;
    transition: 0.4s;
    border-radius: 0;
    border: 2px solid black; /* Apply border to the slider only */
  }
  
  .slider:before {
    position: absolute;
    content: '';
    width: 50%;
    height: 100%;
    background-color: white;
    transition: 0.4s;
    border-radius: 0;
    border-right: 2px solid black;
  }
  
  .slider:after {
    position: absolute;
    content: '\2713';
    font-size: 12px; /* Adjusted font size */
    color: transparent;
    top: 50%;
    left: 50%;
    transform: translate(50%, -50%);
    transition: 0.4s;
  }
  
  input:checked + .slider:before {
    background-color: white;
    border-left: 2px solid black; /* Add border when slider moves to the left */
    width: calc(45% + 2px);
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #594FF5;
  }
  
  input:checked + .slider:before {
    transform: translateX(18px); /* Adjusted translation */
  }
  
  input:checked + .slider:after {
    color: black;
  }
  